import { Component, Vue, Watch } from 'vue-property-decorator';
import { SidemenuItem } from '@/components/sidemenu/Sidemenu';
import { getStatusLabel } from '@/support/ReportStatus';
import { userRoles } from '@/models/User';
import { PermissionSlug } from '@/support/PermissionSlug';

@Component<Werkvoorbereiding>({})
export default class Werkvoorbereiding extends Vue {
  public $pageTitle = 'Dossier statistieken';

  protected isLoading = true;

  protected userRoles = userRoles;

  protected isWerkvoorbereidingAdmin = this.$store.state.Auth.hasRole(this.userRoles.ManagerRoles);

  protected items: SidemenuItem[] = [
    {
      title: 'Dashboard',
      link: '/werkvoorbereiding',
      icon: {
        key: 'dashboard',
      },
    },
    {
      title: 'Mededelingen',
      link: '/werkvoorbereiding/mededelingen',
      icon: {
        key: 'notification_important',
      },
    },
    {
      title: 'BAS import tool werkvoorbereiding',
      link: '/werkvoorbereiding/bas-importtool',
      icon: {
        key: 'cloud_upload',
      },
      visible: this.isWerkvoorbereidingAdmin,
    },
    {
      title: 'Import-tool NAM',
      link: '/werkvoorbereiding/nam-importtool',
      icon: {
        key: 'cloud_upload',
      },
      visible: this.isWerkvoorbereidingAdmin,
    },
  ];

  protected regulier: SidemenuItem[] = [
    {
      title: getStatusLabel('created'),
      link: '/werkvoorbereiding/reports?status=created',
      icon: {
        key: 'keyboard',
      },
    },
    {
      title: getStatusLabel('werkvoorbereiding_rejected'),
      link: '/werkvoorbereiding/reports?status=werkvoorbereiding_rejected',
      icon: {
        key: 'reply_all',
      },
    },
    {
      title: getStatusLabel('opname_werkvoorbereiding_done'),
      link: '/werkvoorbereiding/reports?status=opname_werkvoorbereiding_done',
      icon: {
        key: 'thumb_up',
      },
    },
  ];

  protected aanvragersbeheerSidemenuItems: SidemenuItem[] = [
    {
      title: 'Aanvragers',
      link: '/werkvoorbereiding/aanvragers',
      icon: {
        key: 'groups',
      },
    },
  ];

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    this.emitBreadcrumb();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Werkvoorbereiding' },
        ],
      });
  }

  protected get canManageAanvragers(): boolean {
    return this.$store.state.Auth?.can(PermissionSlug.APPLICANTS_MANAGEMENT);
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
